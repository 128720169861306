import React from 'react';
import logo from './img/logo.png'; // Adjust the path as necessary
import './style/footer.css'
import { EmailOutlined, PhoneOutlined } from '@mui/icons-material';
const Footer = () => {
  return (
    <footer className="py-3 border-top border-success border-3 mt-4">
      <div className="container-fluid py-3 px-4 p-lg-3">
        <div className="row pt-4 mb-4 mb-lg-3">
          <div className="col-12 col-lg-4 mb-4 mb-lg-0">
            <img alt="Beles Tec Company Logo" className="img-fluid mb-3" src={logo} height="100" />
            <p className="mb-1 fw-bold">Mekelle</p>
            <p className="mb-1 fw-bold">Ethiopia</p>
            <a href='tel:+251992120027' className='text-decoration-none text-dark'><p className="mb-1 fw-bold"><PhoneOutlined className='fw-bold'/> :+251 99-212-0027</p></a>
            <p className="mb-0">
              <a className="text-dark text-decoration-none fw-bold" href="mailto:info@belestech.com"><EmailOutlined/> :info@belestech.com</a>
            </p>
            <div className="border-top mt-3 d-lg-none"></div>
          </div>

          <div className="col-sm-12 col-lg-2 mx-auto  p-0">
            <h4>Quick Links</h4>
            <ul className="list-unstyled footer-links px-2  w-100">
              <li className="mb-3"><a className="footernav text-decoration-none" href="/">Home</a></li>
              <li className="mb-3"><a className="footernav  text-decoration-none" href="/research">Research and Development</a></li>
              <li className="mb-3"><a className="footernav text-decoration-none" href="/project">Projects</a></li>
              <li className="mb-3"><a className="footernav text-decoration-none" href="/collab">Collaboration</a></li>
              <li className="mb-3"><a className="footernav text-decoration-none" href="/event">Events</a></li>
            </ul>
          </div>

          <div className="col-sm-12  col-lg-2 mx-auto">
            <h4>Useful Info</h4>
            <ul className="list-unstyled footer-links ">
              <li className="mb-3"><a className="footernav  text-decoration-none" href="/about">About Us</a></li>
              <li className="mb-3"><a className="footernav  text-decoration-none" href="/contact#faq">FAQ</a></li>
            </ul>
          </div>

          <div className="col-sm-12  col-lg-2 mx-auto">
            <h4>Socials</h4>
            <ul className="list-unstyled footer-links">
              <li className="mb-1">
                <a className="text-dark text-decoration-none" href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                  <svg className="bi bi-twitter text-green me-2" fill="currentColor" height="18" viewBox="0 0 16 16" width="18">
                    <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"></path>
                  </svg>
                  Twitter
                </a>
              </li>
              <li className="mb-0">
                <a className="text-dark text-decoration-none" href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                  <svg className="bi bi-facebook text-green me-2" fill="currentColor" height="18" viewBox="0 0 16 16" width="18">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"></path>
                  </svg>
                  Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-top d-lg-none"></div>

        <div className="d-block d-lg-flex justify-content-between py-3 py-lg-2">
          <div className="small mb-2 mb-lg-0 text-muted">
            © 2024 Beles Tec Company. All rights reserved.
          </div>
          <div className="small">
            <a className="d-block d-lg-inline text-muted ms-lg-2 mb-2 mb-lg-0" href="/privacy-policy">Privacy Policy</a>
            <a className="d-block d-lg-inline text-muted ms-lg-2" href="/terms-of-service">Terms of Service</a>
          </div>
         
        </div>
        <div className=' w-100 mt-2  d-flex justify-content-center align-center p-0'>
           <small> <h6 className='text-mute text-center small mt-1'>Developed By</h6></small>
           <a href='https://cubeaddis.com' target='_blank' className='text-decoration-none text-center'><span className='fw-bold px-2'style={{color:"rgb(19,41,112)",cursor:"pointer"}}> Cube Addis Solutions</span></a>
          </div>
      </div>

      <style jsx>{`
        @media (max-width: 576px) {
          .footer-links {
            margin-bottom: 15px; /* Space between footer links */
          }
        }
      `}</style>
    </footer>
  );
};

export default Footer;
